@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: MAL;
  src: url(./fonts/AGRevueCyr\ Roman\ Medium.ttf);
}

body,
html {
  /* background-color: #f0e5c7; */
  background-color: #f5b8af;
  /* background-color: white; */
  margin: 0;
}

#header,
#header2,
#sidebar,
#footer {
  z-index: 5;
  /* border: 1px solid black; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  left: 0;
}

#header,
#footer {
  right: 0;
}

#header {
  border: 0;
  z-index: 5;
  overflow: hidden;
  white-space: nowrap;
  font-family: MAL;
  font-size: 4vh;
  color: #3d423a;
  margin-left: auto;
  margin-right: auto;
  padding: 10;
  text-align: center;
  background-color: #f5b8af;
  height: 6vh;
}

#header:hover {
  text-shadow: 0 0 5px #ff0000;
}

#footer {
  text-align: center;
  background-color: #f5b8af;
  bottom: 0;
  /* height: 30px */
  height: 2vh 10px;
}

#content {
  padding-left: 0%;
  /* padding-top: 130px; */
  padding-bottom: 30px;
}

#heading {
  font-size: 150%;
}

#ukiyo {
  /* border: 1px solid black; */
  object-fit: cover;
  object-position: center;
  /* object-position: top left; */
  /* object-position: right; */
  /* object-position: top; */
  /* padding-top: 6rem; */
  /* padding-bottom: 10%; */
  width: 100vw;
  height: 100vh;
  /* max-height: 50cm; */
  outline: green;
  background-size: auto;
  background-position: center;
}

#arrow {
  cursor: pointer;
  z-index: 1;
  max-height: 20%;
  width: 9vh;
  max-width: 20%;
  color: black;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);

  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(-50%, -50);
  }

  50% {
    transform: translate(-50%, 15px);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

#centered {
  z-index: 1;
  font-size: 130%;
  max-height: 20%;
  width: 35vh;
  overflow: hidden;
  white-space: nowrap;
  background-color: #f0e5c7;
  color: black;
  border: 5px solid #3d392c;
  padding: 1%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#centered:hover,
#centered2:hover,
#centered3:hover {
  background: #f5b8af;
}

#centered2 {
  z-index: 1;
  font-size: 130%;
  max-height: 20%;
  width: 35vh;
  overflow: hidden;
  white-space: nowrap;
  background-color: #f0e5c7;
  color: black;
  border: 5px solid #3d392c;
  padding: 1%;
  position: absolute;
  top: 26.7%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#centered3 {
  z-index: 1;
  font-size: 130%;
  max-height: 20%;
  width: 35vh;
  overflow: hidden;
  white-space: nowrap;
  background-color: #f0e5c7;
  color: black;
  border: 5px solid #3d392c;
  padding: 1%;
  position: absolute;
  top: 73.3%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1000px) {

  #centered,
  #centered3,
  #centered2 {
    font-size: 100%;
  }

  #arrow {
    width: 7vh;
  }
}

@media screen and (orientation: landscape) and (min-device-width: 219px) and (max-device-width: 1200px) and (max-device-height: 1000px) {

  #centered,
  #centered3,
  #centered2 {
    width: 35vw;
    max-height: 15vh;
    font-size: 100%;
  }

  #arrow {
    display: none;
  }
}

.links {
  color: #06212a;
}

.links:hover {
  text-decoration: underline;
}

#image-background {
  text-align: center;
  color: white;
}

#subheading {
  font-family: MAL;
  font-size: 200%;
  text-align: center;
  color: #3d423a;

  padding-top: 6vh;
}

.accordion {
  /* background-color: white; */
  width: 85vw;
  /* height: 70vh; */
}

.item {
  border: 1px solid #06212a;
  background-color: #3d423a;
  /* margin-top: 13vh; */
  margin-top: 5vh;
  /* margin-bottom: 10vh; */
  margin-bottom: 10px;
  padding: 10px 20px;
}

.title {
  font-size: 3vh;
  font-family: mal;
  color: #f5b8af;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.title:hover {
  text-shadow: 0 0 5px #ff0000;
}

.wrapper {
  background-color: #f0e5c7;
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.description {
  color: bisque;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.description.show {
  overflow: auto;
  font-size: auto;
  height: auto;
  /* max-height: 500px; */
  max-height: 30vh;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

#collapse {
  color: #f5b8af;
}

.aniMangaWrapper {
  background-color: #f0e5c7;
  display: flex;
  height: 100vh;
  width: 100%;
  /* justify-content: center; */
  /* align-items: center; */
}

.QuizBorder {
  /* padding-top: 6vh; */
  height: 10vh;
  width: 90vw;
  border: 10px solid red;
  margin: auto;
  margin-top: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.QuizTitle {
  font-family: sans-serif;
  z-index: 1;
  /* font-size: 150%; */
  font-size: 2vw;
}

@media screen and (max-width: 500px) {
  .QuizTitle {
    font-size: 5vw;
  }
}

#animeImage1 {
  border-radius: 20px;
  cursor: pointer;
  z-index: 1;
  max-height: 20%;
  /* width: 9vh; */
  width: 15;
  height: 20vh;
  max-width: 20vh;
  color: black;
  position: absolute;
  top: 60%;
  left: 25%;
  transform: translate(-50%, -50%);
}

#animeImage2 {
  border-radius: 25px;
  cursor: pointer;
  z-index: 1;
  max-height: 20%;
  /* width: 9vh; */
  width: 15;
  height: 20vh;
  max-width: 20vh;
  color: black;
  position: absolute;
  top: 60%;
  left: 75%;
  transform: translate(-50%, -50%);
}